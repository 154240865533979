//import '../scss/app.scss';
import 'jquery';
import 'bootstrap';
//import 'jquery-lazyload';

//import * as jquery from 'jquery';

//var $ = jQuery;
jQuery(document).ready(function(){

	$('.clickable-row').click(function() {
	 	window.document.location = $(this).data('href');
	 });

	$('.clickable-row').hover(
		function(){ $(this).addClass('hover') },
		 function(){ $(this).removeClass('hover') }
	)

    //Delays loading of images
    //$("img.lazy").lazyload();


    //»Scroll Back To Top«-Button mit jQuery
    // Der Button wird mit JavaScript erzeugt und vor dem Ende des body eingebunden.

    var back_to_top_button = ['<a href="#top" class="back-to-top"><i class="material-icons">keyboard_arrow_up</i></a>'].join("");
    $("body").append(back_to_top_button)

    // Der Button wird ausgeblendet
    $(".back-to-top").hide();

    // Funktion für das Scroll-Verhalten
    $(function () {
        $(window).scroll(function () {
            if ($(this).scrollTop() > 100) { // Wenn 100 Pixel gescrolled wurde
                $('.back-to-top').fadeIn();
            } else {
                $('.back-to-top').fadeOut();
            }
        });

        $('.back-to-top').click(function () { // Klick auf den Button
            $('body,html').animate({
                scrollTop: 0
            }, 800);
            return false;
        });
    });

});
    /*
    *   BEGIN: https://github.com/craigbuckler/progressive-image.js
    */
window.addEventListener&&window.requestAnimationFrame&&document.getElementsByClassName&&window.addEventListener("load",function(){var r,e,a=document.getElementsByClassName("progressive replace");(window.addEventListener("scroll",t,!1),window.addEventListener("resize",t,!1),MutationObserver)&&new MutationObserver(function(){a.length!==r&&n()}).observe(document.body,{subtree:!0,childList:!0,attributes:!0,characterData:!0});function t(){e=e||setTimeout(function(){e=null,n()},300)}function n(){a.length&&requestAnimationFrame(function(){for(var e,t,n=window.innerHeight,i=0;i<a.length;)0<(t=(e=a[i].getBoundingClientRect()).top)+e.height&&t<n?(s(a[i]),a[i].classList.remove("replace")):i++;r=a.length})}function s(t){var n=t&&(t.getAttribute("data-href")||t.href);if(n){var i=new Image;t.dataset&&(i.srcset=t.dataset.srcset||"",i.sizes=t.dataset.sizes||""),i.src=n,i.className="reveal",i.complete?e():i.onload=e}function e(){requestAnimationFrame(function(){n===t.href&&(t.style.cursor="default",t.addEventListener("click",function(e){e.preventDefault()},!1));var e=t.querySelector&&t.querySelector("img.preview");t.insertBefore(i,e&&e.nextSibling).addEventListener("animationend",function(){e&&(i.alt=e.alt||"",t.removeChild(e)),i.classList.remove("reveal")})})}}n()},!1);


